<template>
  <div class="mt-3">
    <CRow v-if="!fgInfo" class="justify-content-center m-0">
      <CCol sm="12" lg="4">
        <CSelect
          size="sm"
          :label="$t('label.hazardClass')"
          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
          addLabelClasses="required text-right"
          :options="ImdgClassOptions"
          v-model="VForm.Packaging.SerialImdg.ImdgClass.$model"
          :is-valid="hasError(VForm.Packaging.SerialImdg.ImdgClass)"
          :invalid-feedback="errorMessage(VForm.Packaging.SerialImdg.ImdgClass)"
          @change="listImdgCode($event)"
        />
      </CCol>
      <CCol sm="12" lg="4">
        <CSelect
          size="sm"
          :label="$t('label.imdgCode')"
          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
          addLabelClasses="required text-right"
          :disabled="!Form.Packaging.SerialImdg.ImdgClass"
          :options="ImdgCodeOptions"
          v-model="VForm.Packaging.SerialImdg.ImdgId.$model"
          :is-valid="hasError(VForm.Packaging.SerialImdg.ImdgId)"
          :invalid-feedback="errorMessage(VForm.Packaging.SerialImdg.ImdgId)"
          @change="listUnNumber($event)"
        />
      </CCol>
      <CCol sm="12" lg="4">
        <CSelect
          size="sm"
          :label="$t('label.PackagingGroup')"
          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
          addLabelClasses="text-right"
          :options="PackagingGroupOptions"
          v-model.trim="VForm.Packaging.SerialImdg.PackagingGroup.$model"
          :is-valid="hasError(VForm.Packaging.SerialImdg.PackagingGroup)"
          :invalid-feedback="errorMessage(VForm.Packaging.SerialImdg.PackagingGroup)"
        />
      </CCol>
      <CCol sm="12" lg="4">
        <CSelect
          size="sm"
          :label="$t('label.UnNumber')"
          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
          addLabelClasses="text-right"
          :disabled="!Form.Packaging.SerialImdg.ImdgClass"
          :options="UnNumberOptions"
          v-model.trim="VForm.Packaging.SerialImdg.UnNumber.$model"
          :is-valid="hasError(VForm.Packaging.SerialImdg.UnNumber)"
          :invalid-feedback="errorMessage(VForm.Packaging.SerialImdg.UnNumber)"
        />
      </CCol>
      <CCol sm="12" lg="4">
        <CTextarea
          :label="$t('label.description')"
          :horizontal="{label:'col-sm-12 col-lg-5', input:'col-sm-12 col-lg-7'}"
          size="sm"
          v-uppercase
          v-model.trim="VForm.Packaging.SerialImdg.DescriptionGoods.$model"
          addLabelClasses="text-right"
          rows="2"
          :is-valid="hasError(VForm.Packaging.SerialImdg.DescriptionGoods)"
          :invalid-feedback="errorMessage(VForm.Packaging.SerialImdg.DescriptionGoods)"
        />
      </CCol>
      <CCol sm="12" lg="4">
        <div class="col-sm-12 col-lg-5 col-form-label-sm text-right pl-2" style="text-align: left;">
          <CButton
            color="add"
            size="sm"
            class="mr-1"
            v-c-tooltip="{content: $t('label.add'), placement: 'top-end'}" 
            :disabled="isSubmit"
            @click="SubmitImdg()"
            >
            <CIcon name="checkAlt"/>
          </CButton>
          <CButton
            color="wipe"
            class="justify-content-end"
            size="sm"
            v-c-tooltip="{content: $t('label.cleanInformation'), placement: 'top-end'}" 
            :disabled="isSubmit"
            @click="Clear"
            >
            <CIcon name="cil-brush-alt" />
          </CButton>
        </div>
      </CCol>
    </CRow>
    <CCol sm="12">
      <dataTableExtended
        class="align-center-row-datatable"
        :items="computedListImdg"
        :fields="imdgfields"
        :items-per-page="5"
        column-filter
        pagination
        :table-filter="tableText.tableFilterText"
        :items-per-page-select="tableText.itemsPerPageText"
        :noItemsView="tableText.noItemsViewText"
        sorter
      >
        <template #options="{ item }">
          <td class="text-center">
            <CButton
              color="edit"
              square
              size="sm"
              class="mr-2"
              v-c-tooltip="{
                content: $t('label.edit')+' '+$t('label.Imdg'),
                placement: 'top-end'
                }"
              @click="EditImdg(item)"
            >
              <CIcon name="pencil" />
            </CButton>
            <CButton
              class="btn btn-sm btn-wipe"
              v-c-tooltip="{
                content: $t('label.delete')+$t('label.Imdg'),
                placement: 'top-end',
              }"
              @click="DeleteImdg(item)"
            >
              <CIcon name="cil-trash" />
            </CButton>
          </td>
        </template>
      </dataTableExtended>
    </CCol>
  </div>
  
</template>
<script>
import ModalMixin from '@/_mixins/modal';
import GeneralMixin from '@/_mixins/general';
import UpperCase from '@/_validations/uppercase-directive';

function data() {
  return {
    isSubmit: false,
    ImdgClassList: [],
    ImdgCodeList: [],
    PackagingGroupList: [],
    UnNumberList: [],
    BlImdgGeneralList: [],
  };
}

//methods
function getService() {
  this.$store.state.yardManagement.loading = true;
  let requests = [
    this.$http.ejecutar("GET", "BlImdgGeneral-info"),
  ];
  Promise.all(requests)
    .then((responses) => {
      let Service = responses[0].data.data&&responses[0].data.data[0] ? responses[0].data.data[0] : {};
      //LISTAR CLASE DE PELIGRO
      this.ImdgClassList = Service.ImdgJson ?? [];
      //LISTAR GRUPO DE EMBALAJE
      this.PackagingGroupList = Service.PackagingGroupJson ?? [];
    })
    .catch((err) => {
      this.$notify({
        group: "container",
        title: "¡Error!",
        text: err,
        type: "error",
      });
    })
    .finally(() => {
      this.$store.state.yardManagement.loading = false;
    });
}

function SubmitImdg(){
  try {
    this.isSubmit = true;
    this.VForm.Packaging.SerialImdg.$touch();
    if (this.VForm.Packaging.SerialImdg.$error){
      throw this.$t('label.errorsPleaseCheck');
    }

    if (this.Form.Packaging.SerialImdg.id == 0 && (this.ImdgList.filter(e => e.Status == 1).length != 0) ) {
      throw this.$t('validation.OnlyOneImdgPerPackage');
    }

    if (this.ImdgList.some(e => 
        (this.Form.Packaging.SerialImdg.id==0 ? true : e.IdX != this.Form.Packaging.SerialImdg.id) &&
        e.Status == 1 && 
        e.ImdgClassId == this.Form.Packaging.SerialImdg.ImdgClass && 
        e.ImdgId == this.Form.Packaging.SerialImdg.ImdgId && 
        e.PackagingGroupId == this.Form.Packaging.SerialImdg.PackagingGroup && 
        e.UnNumberId == this.Form.Packaging.SerialImdg.UnNumber
    )) {
      throw this.$t('label.ItemPreviouslyRegistered');
    }

    let _lang = (this.$i18n.locale).charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
    let hazardClassData = this.Form.Packaging.SerialImdg.ImdgClass ? this.ImdgClassList.find((val) => val.ImdgClassId == this.Form.Packaging.SerialImdg.ImdgClass) : {};
    let imdgCodeData = this.Form.Packaging.SerialImdg.ImdgId ? this.ImdgCodeList.find( (val) =>  val.ImdgId == this.Form.Packaging.SerialImdg.ImdgId) : {};
    let UnNumberData = this.Form.Packaging.SerialImdg.UnNumber ? this.UnNumberList.find( (val) =>  val.UnNumberId == this.Form.Packaging.SerialImdg.UnNumber) : {};
    let PackagingGroupData = this.Form.Packaging.SerialImdg.PackagingGroup ? this.PackagingGroupList.find( (val) =>  val.PackagingGroupId == this.Form.Packaging.SerialImdg.PackagingGroup) : {};

    if (this.Form.Packaging.SerialImdg.id==0) {
      this.ImdgList.push({
        IdX: this.ImdgList.length > 0 ? this.ImdgList[this.ImdgList.length - 1].IdX + 1 : 1,
        BlCargoMasterImdgId: '', 
        ImdgCode: imdgCodeData.ImdgCod ?? '',
        ImdgClassCode: hazardClassData.ImdgClassAbbrev ?? '',
        ImdgClassId: this.Form.Packaging.SerialImdg.ImdgClass ?? '',
        ImdgClassName: hazardClassData  ? hazardClassData[`ImdgClassName${_lang}`]: '',
        ImdgId: this.Form.Packaging.SerialImdg.ImdgId ?? '',
        ImdgName: imdgCodeData  ? imdgCodeData[`ImdgName${_lang}`]: '',
        UnNumberId: this.Form.Packaging.SerialImdg.UnNumber ?? '',
        UnNumber: UnNumberData ? UnNumberData.UnNumber: '',
        PackagingGroupId: this.Form.Packaging.SerialImdg.PackagingGroup ?? '',
        PackagingGroupCode: PackagingGroupData ? PackagingGroupData.PackagingGroupCode: '',
        DescriptionOfGoodsDGS: this.Form.Packaging.SerialImdg.DescriptionGoods ?? '',
        Status: 1,
      })
    }else{
      this.$emit('UpdateList', this.ImdgList.map(item => {
        if (item.IdX == this.Form.Packaging.SerialImdg.id) {
          return {
            IdX: item.IdX,
            StowagePlanningBillOfLadingImdgId: item.StowagePlanningBillOfLadingImdgId,
            BlCargoMasterImdgId: item.BlCargoMasterImdgId, 
            ImdgCode: imdgCodeData?.ImdgCod ?? '',
            ImdgClassCode: hazardClassData.ImdgClassAbbrev ?? '',
            ImdgClassId: this.Form.Packaging.SerialImdg.ImdgClass ?? '',
            ImdgClassName: hazardClassData  ? hazardClassData[`ImdgClassName${_lang}`]: '',
            ImdgId: this.Form.Packaging.SerialImdg.ImdgId ?? '',
            ImdgName: imdgCodeData  ? imdgCodeData[`ImdgName${_lang}`]: '',
            UnNumberId: this.Form.Packaging.SerialImdg.UnNumber ?? '',
            UnNumber: UnNumberData ? UnNumberData.UnNumber: '',
            PackagingGroupId: this.Form.Packaging.SerialImdg.PackagingGroup ?? '',
            PackagingGroupCode: PackagingGroupData ? PackagingGroupData.PackagingGroupCode: '',
            DescriptionOfGoodsDGS: this.Form.Packaging.SerialImdg.DescriptionGoods ?? '',
            Status: 1,
          }
        }else{
          return item;
        }
      }));
    }
    this.isSubmit = false;
    this.Clear();
  }catch (error) {
    this.isSubmit = false;
    this.notifyError({text: error});
  }
}

function listImdgCode(event){
  this.Form.Packaging.SerialImdg.ImdgClass = event?.target?.value ?? ''; 

  this.Form.Packaging.SerialImdg.ImdgId = '';
  this.Form.Packaging.SerialImdg.UnNumber = '';
  this.ImdgCodeList = [];
  this.UnNumberList = [];

  let ImdgClasssData = this.ImdgClassList.find((val) => val.ImdgClassId == this.Form.Packaging.SerialImdg.ImdgClass);
  if (ImdgClasssData) {
     this.ImdgCodeList = ImdgClasssData?.ImdgJson ?? [];
  }
}

function listUnNumber(event){
  this.Form.Packaging.SerialImdg.ImdgId = event?.target?.value ?? ''; 
  this.Form.Packaging.SerialImdg.UnNumber = '';
  this.UnNumberList = [];

  let ImdgData = this.ImdgCodeList.find((val) => val.ImdgId == this.Form.Packaging.SerialImdg.ImdgId);
  if (ImdgData) {
     this.UnNumberList = ImdgData?.UnNumberJson ?? [];
  }
}



function EditImdg(item) {
  this.Form.Packaging.SerialImdg.id =  item.IdX;
  this.Form.Packaging.SerialImdg.ImdgClass = item.ImdgClassId;
  this.Form.Packaging.SerialImdg.ImdgId = item.ImdgId;
  this.Form.Packaging.SerialImdg.PackagingGroup = item.PackagingGroupId;
  this.Form.Packaging.SerialImdg.UnNumber = item.UnNumberId;
  this.Form.Packaging.SerialImdg.DescriptionGoods = item.DescriptionOfGoodsDGS;
  this.VForm.Packaging.SerialImdg.$touch();

  let ImdgClasssData = this.ImdgClassList.find((val) => val.ImdgClassId == this.Form.Packaging.SerialImdg.ImdgClass);
  if (ImdgClasssData) {
     this.ImdgCodeList = ImdgClasssData?.ImdgJson ?? [];
     let ImdgCode = ImdgClasssData?.ImdgJson.find((val) => val.ImdgId == item.ImdgId);
     this.UnNumberList = ImdgCode?.UnNumberJson ?? [];
  }
}

function DeleteImdg(item) {
  this.$swal
    .fire(this.alertProperties({
        text: `${this.$t('label.deleteQuestion')}?`,
    }))
    .then((result) => {
      if (result.isConfirmed) {
        if (item.StowagePlanningBillOfLadingImdgId) {
          this.$emit('UpdateList', this.ImdgList.map(Imdg => {
            if (Imdg.StowagePlanningBillOfLadingImdgId == item.StowagePlanningBillOfLadingImdgId) {
              return {
                ...Imdg,
                Status: 0,
              };
            } else {
              return Imdg;
            }
          }));
        }else{
          this.$emit('UpdateList', this.ImdgList.filter(e => e.IdX != item.IdX));
        }
      }
    });
}

function Clear() {
  this.Form.Packaging.SerialImdg.id = 0;
  this.Form.Packaging.SerialImdg.ImdgClass = '';
  this.Form.Packaging.SerialImdg.ImdgId = '';
  this.Form.Packaging.SerialImdg.PackagingGroup = '';
  this.Form.Packaging.SerialImdg.UnNumber = '';
  this.Form.Packaging.SerialImdg.DescriptionGoods = '';
  this.VForm.Packaging.SerialImdg.$touch();
  this.VForm.Packaging.SerialImdg.$reset();
}

function Reset() {
  this.Form.Packaging.SerialImdg.id = 0;
  this.Form.Packaging.SerialImdg.ImdgClass = '';
  this.Form.Packaging.SerialImdg.ImdgId = '';
  this.Form.Packaging.SerialImdg.PackagingGroup = '';
  this.Form.Packaging.SerialImdg.UnNumber = '';
  this.Form.Packaging.SerialImdg.DescriptionGoods = '';
  this.ImdgClassList = [];
  this.ImdgCodeList = [];
  this.PackagingGroupList = [];
  this.UnNumberList = [];
  this.VForm.Packaging.SerialImdg.$reset();
}

//computed
function ImdgClassOptions() {
  let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.ImdgClassList.map(function(e){
    chart.push({
      value: e.ImdgClassId, 
      label: e['ImdgClassName'+_lang] ?  e.ImdgClassAbbrev + ' - ' + e['ImdgClassName'+_lang] : 'N/A'
    })    
  })
  return chart;
}

function ImdgCodeOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.ImdgCodeList.map(function(e){
    chart.push({
      value: e.ImdgId, 
      label: e.ImdgCod
    })    
  })
  return chart;
}

function PackagingGroupOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.PackagingGroupList.map(function(e){
    chart.push({
      value: e.PackagingGroupId, 
      label: e.PackagingGroupCode,
    })    
  })
  return chart;
}

function UnNumberOptions() {
  let chart = [{
    value: '', 
    label: this.$t('label.select'),
  }]
  this.UnNumberList.map(function(e){
    chart.push({
      value: e.UnNumberId, 
      label: e.UnNumber
    })    
  })
  return chart;
}

function computedListImdg() {
  let List = this.ImdgList.filter(item => item.Status != 0);
  return  List.map((item, index) => {
    return {
      ...item,
      Nro: index+1,
      ImdgClassAbbrev: item.ImdgClassCode ?? 'N/A',
      ImdgCod: item.ImdgCode ?? 'N/A',
      PackagingGroupCod: item.PackagingGroupCode ?? 'N/A',
      ComputedUnNumber: item.UnNumber ?? 'N/A',
      ComputedDescriptionOfGoodsDGS: item.DescriptionOfGoodsDGS ?? 'N/A',
    }
  });
}

function imdgfields(){
  let head = [
    { key: 'Nro', label: '#', _style: 'width:3%; text-align:left', _classes: 'text-center', filter: false },
    { key: 'ImdgClassAbbrev', label: this.$t('label.hazardClass'),  _classes: 'text-uppercase text-center', _style: 'width:18%;', sorter: true, filter: true},
    { key: 'ImdgCod', label: this.$t('label.imdgCode'), _classes: 'text-uppercase text-center', _style: 'width:18%;', sorter: true, filter: true},
    { key: 'PackagingGroupCod', label: this.$t('label.PackagingGroup'), _style:'width:18%;', _classes: 'text-uppercase text-center', sorter: true, filter: true},
    { key: 'ComputedUnNumber', label: this.$t('label.UnNumber'), _style:'width:18%;', _classes:"center-cell text-center", sorter: true, filter: true},
  ]
  if (!this.fgInfo) {
    head.push(
      { key: 'ComputedDescriptionOfGoodsDGS', label: this.$t('label.description'), _style:'width:18%;', _classes:"center-cell text-center", sorter: true, filter: true},
      { key: 'options', label: '', _style: 'width:5%; min-width:90px; text-align:center;', sorter: false, filter: false },
    )
  }
  return head;
}

export default {
  name: 'Imdg-tab',
  components: {
  },
  data,
  props: {
    Form: {
        type: Object,
        required: true,
        default: () => {},
    },
    ModuleForm: {
        type: Object,
        required: true,
        default: () => {},
    },
    ImdgList: {
      type: Array,
      required: true,
      default: () => [],
    },
    isEdit: Boolean,
    showModal: Boolean,
    Tab: Boolean,
    fgInfo: Boolean,
    Clean: Boolean,
    VForm: Object,
  },
  mixins: [
    ModalMixin, 
    GeneralMixin
  ],
  directives: UpperCase,
  methods: {
    getService,
    SubmitImdg,
    listImdgCode,
    listUnNumber,
    EditImdg,
    DeleteImdg,
    Clear,
    Reset,
  },
  computed:{
    ImdgClassOptions,
    ImdgCodeOptions,
    PackagingGroupOptions,
    UnNumberOptions,
    computedListImdg,
    imdgfields,
  },
  watch: {
    showModal: function (Newval) {
      if (!Newval) {
        this.Reset();
        this.VForm.Packaging.SerialImdg.$reset();
        this.$v.$reset();
      }
    },
    Tab: function (Newval) {
      if (Newval && !this.fgInfo) {
        this.getService();
      }
    },
    Clean: function (Newval) {
      if (Newval) {
        this.Clear();
      }
    },
  }
};
</script>