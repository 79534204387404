<template>
  <div>
    <CModalExtended
      color="dark"
      :show.sync="showModal"
      :closeOnBackdrop="false"
      size="xl"
    >
      <template #header>
        <h5 class="modal-title">{{titleModal}}</h5>
        <button type="button" aria-label="Close" class="close" @click="closeModal">×</button>
      </template>

      <CRow class="my-1">
        <CCol sm="12">
          <div class="col-sm-12 col-lg-auto px-0 row m-0 mb-2">
              <CCol sm="12" lg="auto">
                  <div class="form-group row d-flex flex-row align-items-center flex-wrap">
                      <div class="col-sm-6 col-lg-auto d-flex align-items-center justify-content-end px-2">
                          <CSwitch
                              color="watch"
                              variant="3d"
                              type="checkbox"
                              :checked.sync="VForm.Packaging.Serialization.IsNewSerial.$model"
                              @update:checked="ChangeNewSerial()"
                              :disabled="this.isEditSerialitation"
                          />
                      </div>
                  </div>
              </CCol>
              <CCol sm="12" lg="auto">
                  <label class="col-form-label-sm col-sm-12 col-lg-12 text-right px-1">{{$t('label.newSerial')}}</label>
              </CCol>
          </div>
          <CTabs variant="tabs" :active-tab="tabIndexSerial" @update:activeTab="handleSerialTab">
              <CTab :title="$t('label.basicData')" :class="(this.VForm.Packaging.Serialization.Serialized.$anyError || this.VForm.Packaging.Serialization.Serial.$anyError || this.VForm.Packaging.Serialization.Weight.$anyError)? 'tab-error' : ''">
                  <CCard class="card-border pt-3">
                      <CRow>
                          <CCol sm="12" lg="4" class="mb-3" v-if="!this.Form.Packaging.Serialization.IsNewSerial">
                              <CSelect
                                  :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                                  :label="$t('label.Serialized')"
                                  :options="serializedOptions"
                                  addLabelClasses="text-right required px-0"
                                  v-model.trim="VForm.Packaging.Serialization.Serialized.$model"
                                  :is-valid="hasError(VForm.Packaging.Serialization.Serialized)"
                                  :invalid-feedback="errorMessage(VForm.Packaging.Serialization.Serialized)"
                                  size="sm"
                                  @blur="VForm.Packaging.Serialization.Serialized.$touch()"
                                  @change="changeSerializedValues"
                                  :disabled="this.isEditSerialitation"
                              />
                          </CCol>
                          <CCol sm="12" lg="4">
                              <CInput
                                v-uppercase
                                size="sm"
                                :placeholder="$t('label.serial')"
                                addLabelClasses="text-right required"
                                :label="$t('label.serial')"
                                :horizontal="{label: 'col-sm-12 col-lg-4', input: 'col-sm-12 col-lg-8'}"
                                v-model="VForm.Packaging.Serialization.Serial.$model"
                                :is-valid="hasError(VForm.Packaging.Serialization.Serial)"
                                :invalid-feedback="errorMessage(VForm.Packaging.Serialization.Serial)"
                              />
                          </CCol>
                          <CCol sm="12" lg="4">
                              <div class="form-group form-row">
                              <label class="text-right col-sm-12 col-lg-4 required col-form-label-sm mb-0">{{`${$t('label.weight')} (KGM)`}}</label>
                              <div class="input-group col-sm-12 col-lg-8 input-group-sm">
                                  <money
                                      size="sm"
                                      v-bind="measureDecimals" 
                                      :class="!VForm.Packaging.Serialization.Weight.$dirty ? 'form-control' : (VForm.Packaging.Serialization.Weight.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                                      v-model="VForm.Packaging.Serialization.Weight.$model"
                                      maxlength= "13"
                                  >
                                  </money>
                                  <div class="invalid-feedback" v-if="VForm.Packaging.Serialization.Weight.$error">
                                      {{ errorMessage(VForm.Packaging.Serialization.Weight) }}
                                  </div>
                              </div>
                              </div>
                          </CCol>
                          <CCol sm="12" lg="4">
                              <CTextarea
                                :label="$t('label.description')"
                                :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                                size="sm"
                                v-uppercase
                                v-model.trim="VForm.Packaging.Serialization.DescriptionOfGoods.$model"
                                addLabelClasses="text-right"
                                rows="2"
                                :is-valid="hasError(VForm.Packaging.Serialization.DescriptionOfGoods)"
                                :invalid-feedback="errorMessage(VForm.Packaging.Serialization.DescriptionOfGoods)"
                              />
                          </CCol>
                      </CRow>
                      <CRow class="flex-grow-1 align-items-center justify-content-start mt-4 px-5" v-if="this.ModuleForm.AccesoryList.length>0">
                          <CCol sm="12" lg="auto">
                              <h6>{{`${$t('label.accesories')}`}} </h6>
                          </CCol>
                      </CRow>
                      <CRow class="justify-content-center" v-if="this.ModuleForm.AccesoryList.length>0">
                        <CCol sm="10">
                            <dataTableExtended
                                class="align-center-row-datatable"
                                :items-per-page="5"
                                :items="computedAccesoryList"
                                :fields="accesoryfields"
                                pagination
                                :noItemsView="tableText.noItemsViewText"
                                sorter
                            >
                                <template #FgChecked="{ item }">
                                    <td class="text-center">
                                        <CInputCheckbox
                                            custom
                                            :checked="item.FgChecked"
                                            class="checkbook-filter ml-1"
                                            @update:checked="(e)=>{changeTableValues(e, item, 'FgChecked')}"
                                        />
                                    </td>
                                </template>

                                <template #Quantity="{ item }">
                                    <td class="text-center">
                                      <div class="input-group input-group-sm col-lg-10 d-flex align-items-center justify-content-center">
                                          <money
                                            v-bind="measure" 
                                            v-model="item.Quantity"
                                            :class="!item.Error ? 'form-control' : (item.Error? 'form-control is-invalid' : 'form-control is-valid')"
                                            class="form-control"
                                            maxlength= "12"
                                            @input="(e)=>{changeTableValues(e, item, 'Quantity')}"
                                          >
                                        </money>
                                        <div class="invalid-feedback" v-if="item.Error">
                                            {{ $t('label.required') }}
                                        </div>
                                      </div>
                                    </td>
                                </template>

                                <template #Observation="{ item }">
                                    <td class="text-center">
                                    <CInput
                                        size="sm"
                                        class="mb-0 pb-0"
                                        :horizontal="{input:'col-sm-12'}"
                                        v-uppercase
                                        v-model.trim="item.Observation"
                                        @input="(e)=>{changeTableValues(e, item, 'Observation')}"
                                    ></CInput>
                                    </td>
                                </template>
                                
                            </dataTableExtended>
                        </CCol>
                    </CRow>
                  </CCard>
              </CTab>
              <CTab :title="$t('label.dimensions')" :class="(this.VForm.Packaging.Serialization.Volumen.$anyError)? 'tab-error' : ''">
                  <CCard class="card-border pt-3">
                      <CRow>
                          <CCol sm="12" lg="4">
                              <div class="form-group form-row">
                                  <div class="input-group col-sm-12 col-lg-4 input-group-sm justify-content-end">
                                      <CSwitch
                                          color="watch"
                                          variant="3d"
                                          type="checkbox"
                                          :checked.sync="VForm.Packaging.Serialization.VolumeCheck.$model"
                                          @change.native="(event)=> changeVolumeSerializationCheck(event)"
                                      />
                                  </div>
                                  <label class="col-sm-12 col-lg-auto col-form-label-sm">{{`${$t('label.automaticCalculation')} (M³)`}}</label>
                              </div>
                          </CCol>
                          <CCol sm="12" lg="8" class="mb-3"></CCol>
                          <CCol sm="12" lg="4">
                              <div class="form-group form-row">
                                  <label class="text-right col-sm-12 col-lg-4 col-form-label-sm">{{`${$t('label.length')} (M)`}}</label>
                                  <div class="input-group col-sm-12 col-lg-8 input-group-sm">
                                      <money
                                          size="sm"
                                          v-bind="measureDecimals" 
                                          :class="!VForm.Packaging.Serialization.Length.$dirty ? 'form-control' : (VForm.Packaging.Serialization.Length.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                                          v-model="VForm.Packaging.Serialization.Length.$model"
                                          addLabelClasses="text-right"
                                          maxlength= "13"
                                          :disabled= "!Form.Packaging.Serialization.VolumeCheck"
                                      >
                                      </money>
                                  </div>
                              </div>
                          </CCol>
                          <CCol sm="12" lg="4">
                              <div class="form-group form-row">
                                  <label class="text-right col-sm-12 col-lg-4 col-form-label-sm">{{`${$t('label.width')} (M)`}}</label>
                                  <div class="input-group col-sm-12 col-lg-8 input-group-sm">
                                      <money
                                          size="sm"
                                          v-bind="measureDecimals" 
                                          :class="!VForm.Packaging.Serialization.Width.$dirty ? 'form-control' : (VForm.Packaging.Serialization.Width.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                                          v-model="VForm.Packaging.Serialization.Width.$model"
                                          addLabelClasses="text-right"
                                          maxlength= "13"
                                          :disabled= "!Form.Packaging.Serialization.VolumeCheck"
                                      >
                                      </money>
                                  </div>
                              </div>
                          </CCol>
                          <CCol sm="12" lg="4">
                              <div class="form-group form-row">
                                  <label class="text-right col-sm-12 col-lg-4 col-form-label-sm">{{`${$t('label.height')} (M)`}}</label>
                                  <div class="input-group col-sm-12 col-lg-8 input-group-sm">
                                      <money
                                          size="sm"
                                          v-bind="measureDecimals" 
                                          :class="!VForm.Packaging.Serialization.Height.$dirty ? 'form-control' : (VForm.Packaging.Serialization.Height.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                                          v-model="VForm.Packaging.Serialization.Height.$model"
                                          addLabelClasses="text-right"
                                          maxlength= "13"
                                          :disabled= "!Form.Packaging.Serialization.VolumeCheck"
                                      >
                                      </money>
                                  </div>
                              </div>
                          </CCol>
                          <CCol sm="12" lg="4">
                              <div class="form-group form-row">
                                  <label class="text-right col-sm-12 col-lg-4 col-form-label-sm px-0">{{`${$t('label.volume')} (M³)`}}</label>
                                  <div class="input-group col-sm-12 col-lg-8 input-group-sm">
                                      <money
                                          v-if="!Form.Packaging.Serialization.VolumeCheck"
                                          size="sm"
                                          v-bind="measureDecimals"
                                          :class="!VForm.Packaging.Serialization.Volumen.$dirty ? 'form-control' : (VForm.Packaging.Serialization.Volumen.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                                          v-model="Form.Packaging.Serialization.Volumen"
                                          addLabelClasses="required text-right"
                                          maxlength= "13"
                                      >
                                      </money>
                                      <money
                                          v-else
                                          size="sm"
                                          v-bind="measureDecimals"
                                          :class="!VForm.Packaging.Serialization.Volumen.$dirty ? 'form-control' : (VForm.Packaging.Serialization.Volumen.$error ? 'form-control is-invalid' : 'form-control is-valid')"
                                          v-model="SerialitationMeters.Volumen"
                                          addLabelClasses="required text-right"
                                          maxlength= "13"
                                          disabled
                                      >
                                      </money>
                                      <div class="invalid-feedback" v-if="VForm.Packaging.Serialization.Volumen.$error">
                                          {{ errorMessage(VForm.Packaging.Serialization.Volumen) }}
                                      </div>
                                  </div>
                              </div>
                          </CCol>
                      </CRow>
                  </CCard>   
              </CTab>
              <CTab :title="$t('label.Seals')">
                  <CCard class="card-border pt-3">
                      <CRow>
                          <CCol sm="12" lg="4">
                              <CInput
                                  size="sm"
                                  :label="`${$t('label.Seal')} 1`"
                                  addLabelClasses="text-right"
                                  :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                                  v-uppercase
                                  :is-valid="hasError(VForm.Packaging.Seal.Seal1.Seal)"
                                  :invalid-feedback="errorMessage(VForm.Packaging.Seal.Seal1.Seal)"
                                  v-model.trim="VForm.Packaging.Seal.Seal1.Seal.$model"
                              >
                              </CInput>
                          </CCol>
                          <CCol sm="12" lg="4">
                              <CInput
                                  size="sm"
                                  :label="`${$t('label.Seal')} 2`"
                                  addLabelClasses="text-right"
                                  :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                                  v-uppercase
                                  :is-valid="hasError(VForm.Packaging.Seal.Seal2.Seal)"
                                  :invalid-feedback="errorMessage(VForm.Packaging.Seal.Seal2.Seal)"
                                  v-model.trim="VForm.Packaging.Seal.Seal2.Seal.$model"
                              >
                              </CInput>
                          </CCol>
                          <CCol sm="12" lg="4">
                              <CInput
                                  size="sm"
                                  :label="`${$t('label.Seal')} 3`"
                                  addLabelClasses="text-right"
                                  :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                                  v-uppercase
                                  :is-valid="hasError(VForm.Packaging.Seal.Seal3.Seal)"
                                  :invalid-feedback="errorMessage(VForm.Packaging.Seal.Seal3.Seal)"
                                  v-model.trim="VForm.Packaging.Seal.Seal3.Seal.$model"
                              >
                              </CInput>
                          </CCol>
                          <CCol sm="12" lg="4">
                              <CInput
                                  size="sm"
                                  :label="`${$t('label.Seal')} 4`"
                                  addLabelClasses="text-right"
                                  :horizontal="{label:'col-sm-12 col-lg-4', input:'col-sm-12 col-lg-8'}"
                                  v-uppercase
                                  :is-valid="hasError(VForm.Packaging.Seal.Seal4.Seal)"
                                  :invalid-feedback="errorMessage(VForm.Packaging.Seal.Seal4.Seal)"
                                  v-model.trim="VForm.Packaging.Seal.Seal4.Seal.$model"
                              >
                              </CInput>
                          </CCol>
                      </CRow>
                  </CCard>   
              </CTab>
              <CTab :title="$t('label.imdg')">
                <CCard class="card-border pt-3">
                  <ImdgTab
                    :Form="Form"
                    :ModuleForm="ModuleForm.SerialImdg"
                    :VForm="VForm" 
                    :ImdgList="ModuleForm.SerialImdg.ImdgList"
                    :Tab="modal"
                    :Clean="CleanInformation"
                    @UpdateList="ModuleForm.SerialImdg.ImdgList=$event"
                  />
                </CCard>
              </CTab>
          </CTabs>
          <CRow>
              <CCol sm="12" lg="12">
                  <div class="col-sm-12 mb-2 text-right px-0" style="text-align: left;">
                  <CButton
                      color="add"
                      size="sm"
                      class="mr-1"
                      v-c-tooltip="{content: $t('label.add'), placement: 'top-end'}" 
                  
                      @click="SubmitSerialitation()"
                  >
                      <CIcon name="checkAlt"/>
                  </CButton>
                  <CButton
                      color="wipe"
                      class="justify-content-end"
                      size="sm"
                      v-c-tooltip="{content: $t('label.cleanInformation'), placement: 'top-end'}" 
                      @click="clearSerialFilters()"
                  >
                      <CIcon name="cil-brush-alt" />
                  </CButton>
                  </div>
              </CCol>
          </CRow>
        </CCol>
        <CCol sm="12" class="justify-content-center">
          <dataTableExtended
              class="align-center-row-datatable"
              :items-per-page="5"
              :items="computedSerializationList"
              :fields="serializationfields"
              column-filter
              pagination
              :table-filter="tableText.tableFilterText"
              :items-per-page-select="tableText.itemsPerPageText"
              :noItemsView="tableText.noItemsViewText"
              sorter
          >

              <template #Status="{ item }">
                  <td class="text-center">
                      <CBadge :color="getBadge(item.Status)">
                          {{ $t('label.'+item.Status) }}
                      </CBadge>
                  </td>
              </template>
              
              <template #options="{ item }">
                  <td class="text-center">
                      <CButton
                          color="edit"
                          square
                          size="sm"
                          class="mr-2"
                          v-c-tooltip="{
                              content: $t('label.edit'),
                              placement: 'top-start'
                              }"
                          @click="EditSerialitation(item)"
                      >
                          <CIcon name="pencil" />
                      </CButton>
                      <CButton
                          class="btn btn-sm btn-wipe"
                          v-c-tooltip="{
                              content: $t('label.delete'),
                              placement: 'top-start',
                          }"
                          @click="DeleteItemSerialized(item)"
                      >
                          <CIcon name="cil-trash" />
                      </CButton>
                  </td>
              </template>
          </dataTableExtended>
          <p class="text-right fw-bold">{{ `
              ${$t('label.totalQuantity')}: ${this.ModuleForm.SerialList.reduce((accumulator, currentValue) => accumulator + (currentValue?.Status != 0 && currentValue?.Quantity ? currentValue.Quantity : 0), 0)} - 
              ${$t('label.totalWeight')}: ${FormatNumber(this.ModuleForm.SerialList.reduce((accumulator, currentValue) => accumulator + (currentValue?.Status != 0 && currentValue?.Weigth ? currentValue.Weigth : 0), 0), 2)} - 
              ${$t('label.TotalVolume')}: ${FormatNumber(this.ModuleForm.SerialList.reduce((accumulator, currentValue) => accumulator + (currentValue?.Status != 0 && currentValue?.Volumen ? currentValue.Volumen : 0), 0), 2)}
          ` }}</p> 
        </CCol>
      </CRow>
      <template #footer>
        <CButton
            square
            color="add"
            class="d-flex align-items-center"
            @click="Submit()"
            >
            <CIcon name="checkAlt"/>{{$t('button.accept')}}
        </CButton>
        <CButton
          color="wipe"
          :disabled="isSubmit"
          class="d-flex align-items-center"
          @click.stop="closeModal"
        >
          <CIcon name="x"/><span class="ml-1">{{ $t('button.exit')}}</span>
        </CButton>
      </template>
    </CModalExtended>
  </div>
</template>
<script>
import UpperCase  from '@/_validations/uppercase-directive';
import GeneralMixin from '@/_mixins/general';
import PictureInput from '@/components/picture-input';
import ImdgTab from './imdg-tab';
import { DateFormater, NumberFormater } from '@/_helpers/funciones';

	function data() {
        return {
            //General
            ImdgList: [],
            CleanInformation: false,
            SerialIdX: 0,
            showModal: false,
            isSubmit: false,
            ModalSerial: false,
            isEditSerialitation: false,
            isCantError: false,
            tabIndex: 0,
            tabIndexSerial:0,
            FgApplySerial: false,
            AccesoryList: [],
            packagingData: {},
            measure: {
                    decimal: ",",
                    thousands: ".",
                    prefix: "",
                    suffix: "",
                    precision: 0,
                    masked: false,
                    maxlength: 18,
            },
            measureDecimals: {
                    decimal: ",",
                    thousands: ".",
                    prefix: "",
                    suffix: "",
                    precision: 2,
                    masked: false,
                    maxlength: 18,
            },

            //Seals 
            ModalSealActive: false,
            SealJson: {},

            //List of selects
            billOfLandingList: [],
            SerializedList: [],
        };
	}

    //-----------------------   Method    ------------------------
  function closeModal() {
    this.ModuleForm.PackagingItem = {};
	this.ModuleForm.SerialList = [];
    this.clearSerialFilters();
    this.$emit('CloseModal');
  }

  function handleSerialTab(tab) {
      this.tabIndexSerial = tab;
  }

  function ChangeNewSerial(value) {
        this.clearSerialFilters(false);

    if(this.Form.Packaging.Serialization.IsNewSerial)
        this.ModuleForm.Serialization.Weight = this.ModuleForm.PackagingItem.referenceWeigth;
    else
        this.ModuleForm.Serialization.Weight = 0;
  }

  function clearSerialFilters(changeToggle = true) {
    this.ModuleForm.Serialization.VolumeCheck = true;

    if(changeToggle)
        this.Form.Packaging.Serialization.IsNewSerial = false;

    this.ModuleForm.Serialization.Id = '';
    this.ModuleForm.Serialization.Serial = '';
    this.ModuleForm.Serialization.PackagingId = '';
    this.ModuleForm.Serialization.Weight = 0;
    this.ModuleForm.Serialization.Volumen = 0;
    this.ModuleForm.Serialization.Length = 0;
    this.ModuleForm.Serialization.Width = 0;
    this.ModuleForm.Serialization.Height = 0;
    this.ModuleForm.Serialization.DescriptionOfGoods = '';
    this.ModuleForm.Serialization.Serialized = '';
    this.SerialIdX = 0;

    this.Form.Packaging.SerialImdg.id = 0;
    this.Form.Packaging.SerialImdg.ImdgList = [];
    this.Form.Packaging.SerialImdg.ImdgClass = '';
    this.Form.Packaging.SerialImdg.ImdgId = '';
    this.Form.Packaging.SerialImdg.PackagingGroup = '';
    this.Form.Packaging.SerialImdg.UnNumber = '';
    this.Form.Packaging.SerialImdg.DescriptionGoods = '';
    this.VForm.Packaging.SerialImdg.$touch();

    //------------- SEAL ----------------
    this.SealForm.Seal1.Seal = '';
    this.SealForm.Seal2.Seal = '';
    this.SealForm.Seal3.Seal = '';
    this.SealForm.Seal4.Seal = '';

    this.isEditSerialitation = false;
    this.isCantError = false;
    setTimeout(() => { this.VForm.Packaging.$reset(); }, 10);
    this.AccesoryList = [];
    this.AccesoryList = this.ModuleForm.AccesoryList.map(e => ({ ...e, Error: false }));
    
  }

  function FormatNumber(number, decimal) {
      return NumberFormater.formatNumber(number, decimal);
  }

  function EditSerialitation(item, isEdit=true){
      this.SerialIdX = item.IdX;
      this.isEditSerialitation = isEdit;
      this.Form.Packaging.Serialization.IsNewSerial = isEdit? item.IsNewSerial : false;
      this.ModuleForm.Serialization.VolumeCheck = isEdit? ((item.VolumeCheck == undefined) ? ((item.Volumen > 0) ? false : true) :  item.VolumeCheck) : false ;
      this.ModuleForm.Serialization.Id = isEdit ? item.Id ?? '' : '';
      this.ModuleForm.Serialization.Serial = item.Serial;
      this.ModuleForm.Serialization.PackagingId = item.PackagingId;
      this.ModuleForm.Serialization.Weight = item.Weigth;
      this.ModuleForm.Serialization.Volumen = isEdit ? item.Volumen : '';
      this.ModuleForm.Serialization.Length = isEdit ? item.Length : '';
      this.ModuleForm.Serialization.Width = isEdit ? item.Width : '';
      this.ModuleForm.Serialization.Height = isEdit ? item.Height : '';
      this.ModuleForm.Serialization.DescriptionOfGoods = isEdit ? item.DescriptionOfGoods : '';
      this.ModuleForm.Serialization.Serialized = item.Serial;
      this.Form.Packaging.Seal.Seal1.Seal = isEdit ? item.Seal1 : '';
      this.Form.Packaging.Seal.Seal2.Seal = isEdit ? item.Seal2 : '';
      this.Form.Packaging.Seal.Seal3.Seal = isEdit ? item.Seal3 : '';
      this.Form.Packaging.Seal.Seal4.Seal = isEdit ? item.Seal4 : '';

      if(isEdit)
        this.AccesoryList = item?.AccesoryJson?.map(e => ({ ...e })) ?? [];
      else
        this.ModuleForm.AccesoryList.map(e => ({ ...e, Error: false }));

      this.ModuleForm.SerialImdg.ImdgList = item?.ImdgJson?.map(e => ({ ...e })) ?? [];

      this.VForm.Packaging.Serialization.$touch();
  }

  function DeleteItemSerialized(item) {
      this.$swal
          .fire(this.alertProperties({
              text: `${this.$t('label.deleteQuestion')}?`,
          }))
      .then((result) => {
      if (result.isConfirmed) {
            if (item.Serial) {
                this.Form.Packaging.SerialList = this.Form.Packaging.SerialList.map(Serial => {
                    if (Serial.Serial == item.Serial) {
                    return {
                        ...Serial,
                        Status: 0,
                    }
                    } else {
                    return Serial;
                    }
                })
            }else{
                this.Form.Packaging.SerialList = this.Form.Packaging.SerialList.filter(e => e.IdX != item.IdX);
            }
            this.clearSerialFilters();
      }
      });
  }

  async function changeSerializedValues(event){
      let data = this.SerializedList.find((e)=>e.Serial == event?.target?.value)

      if(data){

          if(this.SerializedList?.length>2){
              this.Form.Packaging.Serialization.Weight = this.ModuleForm.Quantity * this.referenceWeigth;
          }

          this.EditSerialitation({...data, Serialized: event?.target?.value}, false);
      }
  }

  async function changeTableValues(event, item, name){
    try{
        this.AccesoryList[item.Nro-1].Error= false;
        this.AccesoryList[item.Nro-1][name] = event;
    }
    catch{}
  }

  function SubmitSerialitation(){
      try {
        this.VForm.Packaging.Serialization.$touch();
        
        if (this.VForm.Packaging.Serialization.$error) 
            throw this.$t('label.errorsPleaseCheck');

        if (this.computedSerializationList.some(item => item.Serial == this.ModuleForm.Serialization.Serial && item.Serial != this.ModuleForm.Serialization.Id && item.Status != 0)) 
            throw this.$t('label.ItemPreviouslyRegistered');

        if ((this.computedSerializationList.length + 1) > (this.packagingData.Quantity ? Number.parseInt(this.packagingData.Quantity) : 0) && !this.isEditSerialitation){
            this.isCantError = true;
            throw this.$t('label.packagingNoySerializedQuantity');
        } 

        let errorQuantity = false;

        this.AccesoryList.map(item => {
            if((item.FgChecked) && (item.Quantity  == 0)){
                item.Error = true;
                errorQuantity = true;
            }
            else
                item.Error = false;
        })

        if(errorQuantity) {
            throw this.$t('label.indicateQuantityAccesory');
        }   

        if (this.AccesoryList.some(item => item.Quantity < 0)) 
          throw this.$t('label.quantityOfAccessoryBeNegative');

        this.AccesoryList.map((e)=>{
          if(e.Quantity>0 && !e.FgChecked)
            e.FgChecked = true;
        })

        if (this.isEditSerialitation) {
            
            this.ModuleForm.SerialList = this.computedSerializationList.map(item => {
                
                if ((item.IdX == this.SerialIdX) && item.Status != 0) 
                    return {
                        ...item,
                        Serialized: this.ModuleForm.Serialization.Serial,
                        VolumeCheck: this.ModuleForm.Serialization.VolumeCheck,
                        Id: this.ModuleForm.Serialization.Serial,
                        PackagingId: this.ModuleForm.PackagingItem.PackagingId,
                        Serial: this.ModuleForm.Serialization.Serial,
                        Weigth: NumberFormater.setNum(this.ModuleForm.Serialization.Weight),
                        Volumen: !this.ModuleForm.Serialization.VolumeCheck ?  NumberFormater.setNum(this.ModuleForm.Serialization.Volumen) ?? 0 :  NumberFormater.setNum(this.SerialitationMeters.Volumen),
                        Length: NumberFormater.setNum(this.ModuleForm.Serialization.Length),
                        Width: NumberFormater.setNum(this.ModuleForm.Serialization.Width),
                        Height: NumberFormater.setNum(this.ModuleForm.Serialization.Height),
                        PackagingNameEs: this.packagingData?.PackagingNameEs ?? 'N/A',
                        PackagingNameEn: this.packagingData?.PackagingNameEn ?? 'N/A',
                        Seal1: this.Form.Packaging.Seal.Seal1.Seal,
                        Seal2: this.Form.Packaging.Seal.Seal2.Seal,
                        Seal3: this.Form.Packaging.Seal.Seal3.Seal,
                        Seal4: this.Form.Packaging.Seal.Seal4.Seal,
                        AccesoryJson: this.AccesoryList,
                        ImdgJson: this.ModuleForm.SerialImdg.ImdgList, 
                        Quantity: 1,
                        IsNewSerial: this.Form.Packaging.Serialization.IsNewSerial,
                        DescriptionOfGoods: this.ModuleForm.Serialization.DescriptionOfGoods,
                        Status: 1,
                    }
                else 
                    return item;
            })

            this.clearSerialFilters();
        }

        else {
            this.ModuleForm.SerialList.push({
                IdX: this.ModuleForm.SerialList.length > 0 ? this.ModuleForm.SerialList[this.ModuleForm.SerialList.length - 1].IdX + 1 : 1,
                Serialized: this.ModuleForm.Serialization.Serial,
                VolumeCheck: this.ModuleForm.Serialization.VolumeCheck,
                Id: this.ModuleForm.Serialization.Serial,
                PackagingId: this.ModuleForm.PackagingItem.PackagingId,
                Serial: this.ModuleForm.Serialization.Serial,
                Weigth: NumberFormater.setNum(this.ModuleForm.Serialization.Weight),
                Volumen: !this.ModuleForm.Serialization.VolumeCheck ?  NumberFormater.setNum(this.ModuleForm.Serialization.Volumen) ?? 0 :  NumberFormater.setNum(this.SerialitationMeters.Volumen),
                Length: NumberFormater.setNum(this.ModuleForm.Serialization.Length),
                Width: NumberFormater.setNum(this.ModuleForm.Serialization.Width),
                Height: NumberFormater.setNum(this.ModuleForm.Serialization.Height),
                PackagingNameEs: this.packagingData?.PackagingNameEs ?? 'N/A',
                PackagingNameEn: this.packagingData?.PackagingNameEn ?? 'N/A',
                Seal1: this.Form.Packaging.Seal.Seal1.Seal,
                Seal2: this.Form.Packaging.Seal.Seal2.Seal,
                Seal3: this.Form.Packaging.Seal.Seal3.Seal,
                Seal4: this.Form.Packaging.Seal.Seal4.Seal,
                IsNewSerial: this.Form.Packaging.Serialization.IsNewSerial,
                AccesoryJson: this.AccesoryList,
                ImdgJson: this.ModuleForm.SerialImdg.ImdgList,
                Quantity: 1,
                DescriptionOfGoods: this.ModuleForm.Serialization.DescriptionOfGoods,
                YardCargoBlCargoMasterDetailSerialId:'',
                BlCargoMasterDetailSerialId:  '',
                Status: 1
            });

            this.Form.Packaging.Serialization.IsNewSerial = false;
            this.clearSerialFilters();  
        }

        
        this.tabIndexSerial = 0;
        this.tabIndex = 1;
      } 
      catch (error) {
      this.$notify({
          group: 'container',
          title: '¡Error!',
          text: error,
          type: "error"
      });
      }
  }

  function Submit() {
    let totalQuantity = this.ModuleForm.SerialList.reduce((accumulator, currentValue) => accumulator + (currentValue?.Status != 0 && currentValue?.Quantity ? currentValue.Quantity : 0), 0);
    let totalWeight = FormatNumber(this.ModuleForm.SerialList.reduce((accumulator, currentValue) => accumulator + (currentValue?.Status != 0 && currentValue?.Weigth ? currentValue.Weigth : 0), 0), 2);
    let TotalVolume = FormatNumber(this.ModuleForm.SerialList.reduce((accumulator, currentValue) => accumulator + (currentValue?.Status != 0 && currentValue?.Volumen ? currentValue.Volumen : 0), 0), 2);
    this.$emit('UpdatePackagingSerialList',{SerialList: this.ModuleForm.SerialList, packagingData: this.packagingData, totalQuantity: totalQuantity, totalWeight: totalWeight, TotalVolume: TotalVolume})
  }
    
    //-----------------------   Computed   ------------------------

  function serializationfields(){
      return [
        { key: 'options', label: '', _style: 'width: 1%; min-width:90px; text-align:center;', sorter: false, filter: false },
        { key: 'Nro', label: '#', _style: 'width:3%; text-align:left', _classes: 'text-center', filter: false },
        { key: 'PackagingName', label: this.$t('label.packaging'),  _classes: 'text-uppercase text-center', _style: 'width:20%;', sorter: true, filter: true},
        { key: 'Serial', label: this.$t('label.serial'),  _classes: 'text-uppercase text-center', _style: 'width:20%;', sorter: true, filter: true},
        { key: 'Quantity', label: this.$t('label.quantity'), _classes: 'text-uppercase text-center', _style: 'width:20%;', sorter: true, filter: true},
        { key: 'ComputedWeight', label: `${this.$t('label.weight')} (KGM)`, _classes: 'text-uppercase text-center', _style:'min-width: 190px;',sorter: true, filter: true},
        { key: 'ComputedLength', label: this.$t('label.length'), _classes: 'text-uppercase text-center', _style: 'min-width:150px;', sorter: true, filter: true},
        { key: 'ComputedWidth', label: this.$t('label.width'), _classes: 'text-uppercase text-center', _style: 'min-width:150px;', sorter: true, filter: true},
        { key: 'ComputedHeight', label: this.$t('label.height'), _classes: 'text-uppercase text-center', _style: 'min-width:150px;', sorter: true, filter: true},
        { key: 'ComputedVolume', label: `${this.$t('label.volume')} (M³)`, _classes:"center-cell text-center ", _style:'min-width: 190px;', sorter: true, filter: true},
        { key: 'ImdgClass', label: this.$t('label.hazardClass'), _classes: 'text-uppercase text-center', _style: 'min-width:250px;', sorter: true, filter: true},
        { key: 'imdgCode', label: this.$t('label.imdgCode'), _classes: 'text-uppercase text-center', _style: 'min-width:250px;', sorter: true, filter: true},
        { key: 'PackagingGroup', label: this.$t('label.PackagingGroup'), _classes: 'text-uppercase text-center', _style: 'min-width:250px;', sorter: true, filter: true},
        { key: 'UnNumber', label: this.$t('label.UnNumber'), _classes: 'text-uppercase text-center', _style: 'min-width:250px;', sorter: true, filter: true},
        { key: 'DescriptionGoods', label: `${this.$t('label.imdg')} - ${this.$t('label.description')}`, _classes: 'text-uppercase text-center', _style: 'min-width:250px;', sorter: true, filter: true},
        { key: 'Seals', label: this.$t('label.Seals'), _classes: 'text-uppercase text-center', _style: 'min-width:250px;', sorter: true, filter: true},
        { key: 'DescriptionOfGoods', label: this.$t('label.description'), _classes: 'text-uppercase text-center', _style: 'min-width:250px;', sorter: true, filter: true},
      ];
  }

  function accesoryfields(){
      return [
        { key: 'FgChecked', label: '', _classes: 'text-uppercase text-center', _style: 'width: 1%; min-width:45px;', sorter: false, filter: false},
        { key: 'Accesory', label: this.$t('label.accesory'),  _classes: 'text-uppercase text-center', _style: 'width:31%;'},
        { key: 'Quantity', label: this.$t('label.quantity'),  _classes: 'text-uppercase text-center', _style: 'width:31%;'},
        { key: 'Observation', label: this.$t('label.observation'), _classes: 'text-uppercase text-center', _style: 'width:32%;'}
      ];
  }

  function computedSerializationList() {
      let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);
      let List = this.ModuleForm.SerialList.filter(item => item.PackagingId == this.ModuleForm.PackagingItem.PackagingId && item.Status != 0);

      return List.map((item, index) => {
        let ImdgClass = '', imdgCode = '', PackagingGroup = '', UnNumber = '', DescriptionGoods = '';

        item.ImdgJson.map((e)=>{
            ImdgClass = e.ImdgClassName ?? '';
            imdgCode = e.ImdgCode ?? '';
            PackagingGroup = e.PackagingGroupCode ?? '';
            UnNumber = e.UnNumber ?? '';
            DescriptionGoods = e.DescriptionOfGoodsDGS ?? '';
        })

        return {
          ...item,
          Nro: index+1,
          PackagingName: item[`PackagingName${_lang}`] ?? 'N/A',
          Quantity: item.Quantity ?? 0,
          ComputedWeight: item.Weigth ? NumberFormater.formatNumber(item.Weigth, 2) : '0,00',
          ComputedLength: item.Length ? NumberFormater.formatNumber(item.Length, 2) : '0,00',
          ComputedWidth: item.Width ? NumberFormater.formatNumber(item.Width, 2) : '0,00',
          ComputedHeight: item.Height ? NumberFormater.formatNumber(item.Height, 2) : '0,00',
          ComputedVolume: item.Volumen ? NumberFormater.formatNumber(item.Volumen, 2) : '0,00',
          ImdgClass: ImdgClass,
          imdgCode: imdgCode, 
          PackagingGroup: PackagingGroup, 
          UnNumber: UnNumber, 
          DescriptionGoods: DescriptionGoods,
          DescriptionOfGoods: item.DescriptionOfGoods ?? '',
          Seals: ` ${(item.Seal1 && item.Seal1 != '')? ` S1: (${item.Seal1})`: ''}${(item.Seal1 && item.Seal1 != '')? `,`: ''} 
                    ${(item.Seal2 && item.Seal2 != '')? ` S2: (${item.Seal2})`: ''}${(item.Seal2 && item.Seal2 != '')? `,`: ''} 
                    ${(item.Seal3 && item.Seal3 != '')? ` S3: (${item.Seal3})`: ''}${(item.Seal3 && item.Seal3 != '')? `,`: ''} 
                    ${(item.Seal4 && item.Seal4 != '')? ` S4: (${item.Seal4})`: ''}
                  `,
          }
      });
  }

  function computedAccesoryList() {
      let _lang = this.$i18n.locale.charAt(0).toUpperCase() + (this.$i18n.locale).slice(1);

      return this.AccesoryList.map((item, index) => {
            return {
                ...item,
                Nro: index+1,
                Accesory: item[`PackagingAccesoryName${_lang}`] ?? 'N/A',
                Quantity: item.Quantity ?? 0
            }
      });
  }

  function serializedOptions(){
      var chart = [{
      value: '',
      label: this.$t('label.select'),
      }]
      this.SerializedList.map((e) => {
      chart.push({
          value: e.Serial,
          label: e.Serial
      });
      })
      return chart;
  }

  function SerialitationMeters() {
      this.Form.Packaging.Serialization.Volumen = NumberFormater.setNum( this.Form.Packaging.Serialization.Length) * NumberFormater.setNum( this.Form.Packaging.Serialization.Width) * NumberFormater.setNum( this.Form.Packaging.Serialization.Height);
      return {
          Volumen: NumberFormater.setNum( this.Form.Packaging.Serialization.Length) * NumberFormater.setNum( this.Form.Packaging.Serialization.Width) * NumberFormater.setNum( this.Form.Packaging.Serialization.Height)
      }
  }

  function SerialitationVolumen() {
      let totalVolumen = FormatNumber(this.ModuleForm.SerialList.reduce((accumulator, currentValue) => accumulator + (currentValue?.Status != 0 && currentValue?.Volumen ? currentValue.Volumen : 0), 0), 2);
      return totalVolumen;
  }

  function SerialitationWeigth() {
      let totalWeigth = FormatNumber(this.ModuleForm.SerialList.reduce((accumulator, currentValue) => accumulator + (currentValue?.Status != 0 && currentValue?.Weigth ? currentValue.Weigth : 0), 0), 2);
      return totalWeigth;
  }

  function changeVolumeSerializationCheck() {
      this.Form.Packaging.Serialization.Length = 0;
      this.Form.Packaging.Serialization.Width = 0;
      this.Form.Packaging.Serialization.Height = 0;
      this.Form.Packaging.Serialization.Volumen = 0;
  }

  function changeSerializationCheck() {
    if(!this.FgApplySerial){
        this.$swal.fire(this.alertProperties({
            text: `${this.$t('label.sureNotSerializePackaging')}`,
        }))
        .then((result) => {
            if (result.isConfirmed) {
                this.clearSerialFilters();
                this.tabIndex = 0;
                this.ModuleForm.SerialList = [];
                setTimeout(() => {this.VForm.Packaging.$reset();}, 30);
            }
            else{
                this.FgApplySerial = true;
                this.tabIndex = 1;
                setTimeout(() => {this.VForm.Packaging.$reset();}, 30);
            }  
        });

        this.tabIndex = 0;
    }
  }

  export default {
    name: 'modal-serials',
    components: {
      PictureInput,
      ImdgTab
    },
    directives: UpperCase,
    data,
    props: {
      modal: null,
      isEdit: Boolean,
      titleModal: String,
      Form: {
          type: Object,
          required: true,
          default: () => {},
      },
      ModuleForm: {
          type: Object,
          required: true,
          default: () => {},
      },
      SealForm: {
          type: Object,
          required: true,
          default: () => {},
      },
      PackagingList: {
          type: Array,
          required: true,
          default: [],
      },
      Quantity: Number,
      isEdit: Boolean,
      Tab: Boolean,
      Active: Boolean,
      VForm: Object,
      referenceWeigth: Number
    },
    mixins: [GeneralMixin],
    methods: {
      clearSerialFilters,
      FormatNumber,
      changeVolumeSerializationCheck,
      changeSerializationCheck,
      SubmitSerialitation,
      EditSerialitation,
      handleSerialTab,
      DeleteItemSerialized,
      changeSerializedValues,
      ChangeNewSerial,
      closeModal,
      Submit,
      changeTableValues
    },
    computed: {
      SerialitationMeters,
      computedSerializationList,
      serializationfields,
      serializedOptions,
      SerialitationVolumen,
      SerialitationWeigth,
      accesoryfields,
      computedAccesoryList
    },
    watch: {
      modal: async function (val) {
        this.showModal = val;
        if (val && this.ModuleForm.PackagingItem.PackagingId) {
            this.packagingData = this.Form.DetailJson.find( (val) =>  (val.BlCargoMasterDetailId == this.ModuleForm.PackagingItem.BlCargoMasterDetailId) && val.Status != 0);
            this.ModuleForm.SerialList = this.packagingData?.SerialJson.map((e)=> {return {...e}});
            let data = this.PackagingList.find((e)=>e.BlCargoMasterDetailId == this.ModuleForm.PackagingItem.BlCargoMasterDetailId) ?? [];
            //this.SerializedList = this.SerializedList.concat(this.ModuleForm.PackagingItem.SerialJson);

            if(data){
              this.SerializedList = data.SerialJson;
              setTimeout(() => {this.VForm.Packaging.$reset();});
            }

            this.clearSerialFilters();
        }
      },
      isEditSerialitation: async function (val) {
        this.$emit('isEditSerialitation',val)
      },
    }
    };
  </script>